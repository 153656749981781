import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Signature from "../../../../helpers/Signature";
import Sidebar from "../../Sidebar";
import CryptoJS from "crypto-js";
import axios from "axios";
import { randCurrentTimestamp } from "../../../helpers/RandomGenerator";

const AdminList = () => {
    const navigate = useNavigate();
    const [adminList, setAdminList] = useState([]);

    const [reachMaxList, setReachMaxList] = useState(false);
    const [page, setPage] = useState(0);
    const [totalList] = useState(10);

    const loadAdmin = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/admin/v01/list?limit=100'+'&rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setAdminList(response.data.data.data);
        }
    }, []);

    useEffect(() => {
        loadAdmin();
    }, [loadAdmin]);

    const loadPaginationData = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/admin/v01/list?limit='+totalList+'&page='+page+'&rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.data.data.length === 0) {
            setReachMaxList(true)
        }else{
            setAdminList((adminList) => [...adminList, ...response.data.data.data])
        }
    }

    const scrollHandle = (e) => {
        const scrollTop = Math.round(e.target.scrollTop)
        
        if (e.target.scrollHeight - scrollTop === e.target.clientHeight){
            if(!reachMaxList){
                setPage(page + 1);
            }
        }
    }

    useEffect(() => {
        if (page !== 0) {
            if (!reachMaxList) {
                loadPaginationData(page);
            }
        }
    }, [page]);

    return(
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar/>

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex-1">
                                            <div className="flex flex-row items-center gap-3">
                                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                                <div className="text-[30px]">Admin List</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... / Admin /</div>
                                        </div>
                                        <button onClick={() => navigate('/admin/add')} className="flex px-12 py-4 h-full bg-blue-cst text-[14px] justify-center items-center rounded-[6px]">
                                            + Add Admin
                                        </button>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-full mt-5">
                                            <div className="relative overflow-x-auto overflow-y-auto h-[700px]" onScroll={(e) => scrollHandle(e)}>
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="text-white-primary bg-black-toGray">
                                                            <th scope="col" className="py-3 pl-3 rounded-l-lg ">
                                                                Nama
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Role
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3 text-center">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {adminList.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" key={key}>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.admin_name}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.admin_position}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold text-center capitalize">
                                                                    <button onClick={() => navigate('/admin/detail/'+result.admin_index)}>
                                                                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect x="0.666626" width="40" height="40" rx="8" fill="#0028FC"/>
                                                                            <path d="M23.3602 14.3602L26.3064 17.3065M24.6102 13.1102C25.4238 12.2966 26.7429 12.2966 27.5564 13.1102C28.37 13.9238 28.37 15.2429 27.5564 16.0565L16.0834 27.5296H13.1667V24.5537L24.6102 13.1102Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminList;