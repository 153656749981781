import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../Sidebar";

import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import FormatDate from "../../../../helpers/FormatDate";
import { randCurrentTimestamp } from "../../../../helpers/RandomGenerator";

const PgcList = () => {
    const [transactionList, setTransactionList] = useState([]);
    const [date, setDate] = useState('');
    const navigate = useNavigate();

    const dateToday = () => {
        let today = new Date();
        let date = today.getDate();
        let month = (today.getMonth() + 1).toString().padStart(2, '0');
        let year = today.getFullYear();

        let result = year + '-' + month + '-' + date;
        setDate(result);

        return result;
    }

    useEffect(() => {
        setDate(FormatDate(new Date()));
    }, []);

    const loadTransactionPgcList = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/pgc?date=' + FormatDate(new Date())+'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });
        console.log('res_v01/pgc', response)
        if (response.data.status === 1) {
            setTransactionList(response.data.data);
        }
    }, []);

    useEffect(() => {
        loadTransactionPgcList();
    }, [loadTransactionPgcList]);

    const handleFilterDate = async (e) => {
        setDate(e.target.value);

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/pgc?date=' + e.target.value +'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });
        console.log('res_v01/pgc', response)
        if (response.data.status === 1) {
            setTransactionList(response.data.data);
        }
    }
    return (
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar />

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                                <div className="text-[30px]">Transaction</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... /Transaction/</div>
                                        </div>
                                        {/* <div className="flex flex-row w-[25%] h-[50px]">
                                            <button className="flex w-[50%] h-full bg-blue-cst text-[14px] rounded-l-[6px] justify-center items-center border-[2px] border-white-primary">
                                                Top Up
                                            </button>
                                            <button className="flex w-[50%] h-full text-[14px] bg-white-primary text-gray rounded-r-[6px] justify-center items-center border-[2px] border-white-primary">
                                                Transfer
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className=" w-[80%] mt-5">
                                            <div className="relative overflow-x-auto overflow-y-auto h-[700px] pr-3">
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="text-white-primary bg-black-toGray">
                                                            <th scope="col" className="py-3 pl-3">
                                                                Transaction Code
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Date
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Invoice Number
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Recepient
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Quotation Index
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Type
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Unique Code
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Amount
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Total
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Fee
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Currency
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Platform
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Status
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactionList.length > 0 ? 
                                                        transactionList.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" key={key} onClick={() => navigate('/transaction/pgc/' + result.transactioncode + '/' + date)}>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.transactioncode}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize whitespace-nowrap">
                                                                    {new Date(result.timestamp).toLocaleString("en-US", {
                                                                        year: "numeric",
                                                                        month: "2-digit",
                                                                        day: "2-digit",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit",
                                                                        timeZone: "UTC"
                                                                    })}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.invoicenumber}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.recepient}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.tableinvoicequotationindex}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.tableinvoiceuniquecode}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.type}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.amount}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.tableinvoicetotal}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.fee}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.tableinvoiceplatformcode}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.tableinvoicecurrencycode}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.status}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    <button onClick={() => navigate('/transaction/pgc/' + result.transactioncode + '/' + date)}>
                                                                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect x="0.166656" width="40" height="40" rx="8" fill="#0028FC" />
                                                                            <path d="M17.6666 20C17.6666 21.3807 18.7859 22.5 20.1666 22.5C21.5474 22.5 22.6666 21.3807 22.6666 20C22.6666 18.6193 21.5474 17.5 20.1666 17.5C18.7859 17.5 17.6666 18.6193 17.6666 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M28.1181 20C27.0562 16.6191 23.8976 14.1667 20.1663 14.1667C16.4349 14.1667 13.2763 16.6191 12.2144 20.0001C13.2763 23.381 16.4349 25.8334 20.1663 25.8334C23.8976 25.8334 27.0562 23.3809 28.1181 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer">
                                                            <td className="py-6 pl-3 font-semibold text-center capitalize" colSpan={15}>
                                                                Data is empty
                                                            </td>
                                                        </tr> }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="flex flex-col min-h-full w-[20%] gap-6">
                                            {/* <div className="block">
                                                <span className="text-[14px] text-white-primary mb-3">Search</span>
                                                    <div className="flex flex-row  bg-black-toGray rounded-[4px] px-2 py-2 gap-2 mt-3">

                                                    <input type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="Field"></input>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5 16.5L6.5 11.5M4.83333 7.33333C4.83333 10.555 7.44501 13.1667 10.6667 13.1667C13.8883 13.1667 16.5 10.555 16.5 7.33333C16.5 4.11167 13.8883 1.5 10.6667 1.5C7.44501 1.5 4.83333 4.11167 4.83333 7.33333Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div>
                                                <span className="text-[14px] text-white-primary mb-3">Filter by Date</span>
                                                <div className="flex flex-row gap-5 mt-3">
                                                    <input type="date" onChange={(e) => handleFilterDate(e)} value={date} className="h-[40px] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2"></input>
                                                </div>
                                            </div>
                                            {/* <div className="flex flex-col h-[100%] gap-10 mt-3">
                                                <div className="flex flex-col h-[45%]">
                                                    <span className="text-[14px] text-white-primary">Filter By Type</span>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Transfer</button>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Top Up</button>
                                                </div>
                                                <div className="flex flex-col h-[45%]">
                                                    <span className="text-[14px] text-white-primary">Filter By Status</span>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Unpaid</button>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Paid</button>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Confirmed</button>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Completed</button>
                                                    <button className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Canceled</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PgcList;