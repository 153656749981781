import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import Signature from "../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import { useNavigate, useParams } from "react-router-dom";
import { Validator } from "../../../../helpers/Validator";
import { randCurrentTimestamp } from "../../../helpers/RandomGenerator";

const DetailUser = () => {

    //UserDetail
    const [useremail, setUseremail] = useState('');
    const [userjoindate, setUserjoindate] = useState('')
    const [userlastlogin, setUserlastlogin] = useState('')
    const [username, setUsername] = useState('')
    // const [userphonenumber, setUserphonenumber] = useState('')
    const [userstatusdescription, setUserstatusdescription] = useState('');
    //KYC
    // const [userfullname, setUserfullname] = useState('');
    const [useridnumber, setUseridnumber] = useState('');
    const [usergender, setUsergender] = useState('');
    const [userbirthplace, setUserbirthplace] = useState('');
    const [userbirthdate, setUserbirthdate] = useState('');
    const [useraddress, setUseraddress] = useState('');
    const [usersubdisctrict, setUsersubdisctrict] = useState('');
    const [userdisctrict, setUserdisctrict] = useState('');
    const [usercity, setUsercity] = useState('');
    const [userprovince, setUserprovince] = useState('');
    const [userstate, setUserstate] = useState('');
    const [userpostalcode, setUserpostalcode] = useState('');
    const [allowApproveKYC, setAllowApproveKYC] = useState(false);
    const [allowRejectKYC, setAllowRejectKYC] = useState(false);
    const [KTPImageUrl, setKTPImageUrl] = useState('');
    const [UserImageUrl, setUserImageUrl] = useState('');
    const [userkycstatusdescription, setUserkycstatusdescription] = useState('');
    //action
    const [openKYC, setOpenKYC] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [isClosed] = useState(false);
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const loadDetailUser = useCallback(async () => {

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        try {
            let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/user/v02/' + id +'?rand='+ randCurrentTimestamp, { headers: dataHeaders })
            console.log("RESPONSE", response)
            if (response.data.status === 1) {

                const data = response.data.data.userdetail
                console.log(data)
                setUseremail(data.useremail);
                setUserjoindate(Validator.modifyDate(data.userjoindate));
                setUserlastlogin(Validator.modifyDate(data.userlastlogin));
                // setUserphonenumber(data.userphonenumber);
                setUserstatusdescription(data.userstatusdescription);
                setUsername(data.username);

                if ((data.userstatusdescription).toLowerCase() === 'blocked') {
                    setIsBlocked(true)
                }

                const kyc = response.data.data.userkyc

                // setUserfullname(kyc.userfullname);
                setUseridnumber(kyc.useridnumber);
                setUsergender(kyc.usergender);
                setUserbirthplace(kyc.userbirthplace);
                setUserbirthdate(Validator.modifyBirthDate(kyc.userbirthdate));
                setUseraddress(kyc.useraddress);
                setUsersubdisctrict(kyc.usersubdisctrict);
                setUserdisctrict(kyc.userdisctrict);
                setUsercity(kyc.usercity);
                setUserprovince(kyc.userprovince);
                setUserstate(kyc.userstate);
                setUserpostalcode(kyc.userpostalcode);
                setKTPImageUrl(kyc.userktpphotourl);
                setUserImageUrl(kyc.userphotourl);
                setUserkycstatusdescription(kyc.userkycstatusdescription);

                if (kyc.userkycstatuscode === 1) {
                    setAllowApproveKYC(true)
                    setAllowRejectKYC(true)
                }
                if (kyc.userkycstatuscode === 2) {
                    setAllowApproveKYC(false)
                    setAllowRejectKYC(false)
                }
            }
        } catch (err) {
            console.log("ERROR-1", err)
            console.log("ERROR-2", err.response)
            if (err.response.status === 403) {
                navigate('/logout', {
                    userStatus:
                    {
                        tokenIsExpired: true
                    }
                })
            }
        }

    }, [id])
    const handleModal = (message) => {
        console.log('ok');

        switch (message) {
            case "Block":
                console.log(message)
                setShowModal(true)
                setModalMessage("Block")
                break;
            case "Approve":
                console.log(message)
                setShowModal(true)
                setModalMessage("Approve")
                break;
            case "Reject":
                console.log(message)
                setShowModal(true)
                setModalMessage("Reject")
                break;
            default:
                break;
        }
    }
    const handleActionModal = (message) => {
        console.log(message)
        switch (message) {
            case "Block":
                console.log(message)
                blockUser();
                break;
            case "Approve":
                console.log(message)
                approveKYC();
                break;
            case "Reject":
                console.log(message)
                rejectKYC();
                break;
            case 'NEED REVIEW':
                break;
            default:
                break;
        }
    }
    const blockUser = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        try {
            let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/user/v02/block/' + id +'?rand='+ randCurrentTimestamp, { headers: dataHeaders })
            console.log('BLOCK', response)
            if (response.data.status === 1) {
                setIsBlocked(true)
                setShowModal(false);
                loadDetailUser();
            }
            else {
                const error = response.data.message[0]
                console.log(error.code, error.message)
            }
        }
        catch (err) {
            console.log("ERROR-1", err)
            console.log("ERROR-2", err.response)
            if (err.response.status === 403) {
                navigate('/logout', {
                    userStatus:
                    {
                        tokenIsExpired: true
                    }
                })
            }
        }

    }
    const switchMenu = (id) => {
        if (id === "openKYC") {
            setOpenKYC(true)
        }
        else {
            setOpenKYC(false)
        }

    }
    const approveKYC = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        try {
            let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/user/v02/kyc/approve/' + id +'?rand='+ randCurrentTimestamp, { headers: dataHeaders })
            console.log('approveKYC', response)
            if (response.data.status === 1) {
                setAllowApproveKYC(false)
                setShowModal(false);
                loadDetailUser();
            }
        } catch (err) {
            console.log("ERROR-1", err)
            console.log("ERROR-2", err.response)
            if (err.response.status === 403) {
                navigate('/logout', {
                    userStatus:
                    {
                        tokenIsExpired: true
                    }
                })
            }
        }

    }
    const rejectKYC = async () => {
        console.log('RECJECT RUNNING');
        console.log('ID REJECT', id);
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        try {
            let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/user/v02/kyc/reject/' + id +'?rand='+ randCurrentTimestamp, { headers: dataHeaders })
            console.log('rejectKYC', response)
            if (response.data.status === 1) {
                setAllowRejectKYC(false)
                setShowModal(false);
                loadDetailUser();
            }

            console.log('RESPONSE DATA ', response.data);
        }
        catch (err) {
            console.log("ERROR-1", err)
            console.log("ERROR-2", err.response)
            if (err.response.status === 403) {
                navigate('/logout', {
                    userStatus:
                    {
                        tokenIsExpired: true
                    }
                })
            }
        }

    }
    useEffect(() => {
        loadDetailUser();
    }, [loadDetailUser]);

    const ActiveHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/user/v02/activate/'+id +'?rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            loadDetailUser();
        }
    }

    return (
        <>

            <div className="w-full min-h-screen p-4 bg-pure-black">

                <Sidebar />
                {showModal ? (
                    <>
                        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                            <div className="w-[40%] my-6 mx-3 max-w-3xl">
                                <div className="border-2 rounded-2xl shadow-lg relative flex flex-col w-full bg-black-toGray border-[#3D3F40] outline-none focus:outline-none">
                                    {/* <button
                                    className="float-right p-1 ml-auto mr-4 text-3xl font-bold leading-none text-gray-400 border-0 outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="block w-6 h-6 text-2xl text-gray-400 outline-none focus:outline-none">
                                        x
                                    </span>
                                </button> */}
                                    <div className="flex flex-col items-center gap-8 p-12">
                                        <img src={process.env.PUBLIC_URL + '/images/components/confirmation/CST_Confirmation.svg'} alt="error" className=""></img>
                                        <h3 className="font-medium text-white uppercase">{"Are you sure?"}</h3>
                                        <div className="flex flex-row w-full justify-evenly">
                                            <button className="border-2 rounded-lg w-[45%] py-2 bg-black-toGray border-blue-action-button text-white-primary" onClick={() => setShowModal(false)}>Cancel</button>
                                            <button className="border-0 rounded-lg w-[45%] bg-blue-action-button text-white-primary" onClick={() => handleActionModal(modalMessage)}>{modalMessage}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                    </>
                ) : null}

                <div className="w-full min-h-screen pl-[295px]">
                    <div className="flex flex-col w-full h-full">

                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px] ">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                                <div className="text-[30px]">Detail User</div>
                                            </div>

                                            <div className="text-[#BABEC1]">../User/DetailUser</div>
                                        </div>
                                        <div className="flex flex-row w-[25%] h-[50px]">
                                            <button className={!openKYC ? "flex w-[50%] h-full bg-blue-cst text-[14px] rounded-l-[6px] justify-center items-center border-[2px] border-white-primary" :
                                                "flex w-[50%] h-full bg-white-primary rounded-l-[6px] justify-center text-[14px] items-center border-[2px] text-gray border-white-primary"
                                            } onClick={() => switchMenu("closeKYC")}>
                                                Account Data
                                            </button>
                                            <button onClick={() => switchMenu("openKYC")} className={openKYC ? "flex w-[50%] h-full text-[14px] bg-blue-cst rounded-r-[6px] justify-center items-center border-[2px] border-white-primary" : "flex w-[50%] h-fulltext-[14px] bg-white-primary rounded-r-[6px] justify-center items-center border-[2px] text-gray border-white-primary "}>
                                                KYC Data
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex min-h-screen mt-5">
                                        {
                                            openKYC ?
                                                <>
                                                    {
                                                        userkycstatusdescription === "KYC Needed" ?
                                                            <>
                                                                <div className="w-[70%] pr-3">
                                                                    <span className="text-white-primary text-[20px]">KYC Data</span>
                                                                    <div className="flex flex-col items-center justify-center py-10">
                                                                        {/* <img style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/logo/CST_NotYet.svg'})` }}></img> */}
                                                                        <img src={process.env.PUBLIC_URL + '/images/logo/CST_NotYet.svg'} alt="no Data" className="logo-desktop "></img>
                                                                    </div>
                                                                </div>
                                                            </> :
                                                            <div className="w-[70%] pr-3">
                                                                <span className="text-white-primary text-[20px]">KYC Data</span>
                                                                <div className="relative mt-3 overflow-x-hidden">
                                                                    <table className="w-full text-sm text-left">
                                                                        <tbody>
                                                                            <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Full Name
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {useremail}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    ID Number
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {useridnumber}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Gender
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {usergender}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Place of Birth
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userbirthplace}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Date of Birth
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userbirthdate}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Address
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {useraddress}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Subdistrict
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {usersubdisctrict}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    District
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userdisctrict}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    City
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {usercity}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Province
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userprovince}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    State
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userstate}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                                <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                                    Postal Code
                                                                                </td>
                                                                                <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                                    {userpostalcode}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>

                                                                    </table>
                                                                </div>
                                                                <div className="flex flex-col gap-3 mt-6">
                                                                    <span className="text-[20px] text-white-primary">Additional User Data</span>
                                                                    <div className="flex flex-col w-full gap-10 px-6 py-3 bg-black-toGray">
                                                                        <div>
                                                                            <span className="text-light-gray">KTP</span>
                                                                        </div>
                                                                        <div className="flex items-center justify-center w-full bg-black-toGray">
                                                                            <img className="h-[300px]  object-fill" alt="KTP" src={KTPImageUrl}></img>
                                                                        </div>

                                                                    </div>
                                                                    <div className="flex flex-col w-full gap-10 px-6 py-3 bg-black-toGray">
                                                                        <div>
                                                                            <span className="text-light-gray">User Photo</span>
                                                                        </div>
                                                                        <div className="flex items-center justify-center w-full bg-black-toGray">
                                                                            <img className="h-[300px]  object-fill" alt="UserPhoto" src={UserImageUrl}></img>
                                                                        </div>

                                                                    </div>
                                                                    {/* <div>
                                                        <span className="text-white-primary">User Photo</span>
                                                        <img src={UserImageUrl}></img>
                                                    </div> */}

                                                                </div>

                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div className="w-[70%] pr-3">
                                                    <span className="text-white-primary text-[20px]">Account Data</span>
                                                    <div className="relative mt-3 overflow-x-hidden">
                                                        <table className="w-full text-sm text-left">
                                                            <tbody>
                                                                <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                    <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                        Email
                                                                    </td>
                                                                    <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                        {useremail}
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                    <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                        Username
                                                                    </td>
                                                                    <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                        {username}
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                    <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                        Status Akun
                                                                    </td>
                                                                    <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                        {userstatusdescription}
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                    <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                        Join Date
                                                                    </td>
                                                                    <td className="flex flex-row gap-3 py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                        <img src={process.env.PUBLIC_URL + '/images/logo/Clock.svg'} alt="clockJoinDate"></img>{userjoindate}
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                                    <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                        Last Login
                                                                    </td>
                                                                    <td className="flex flex-row gap-3 py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                        <img src={process.env.PUBLIC_URL + '/images/logo/Clock.svg'} alt="clockLastLogin" ></img>{userlastlogin}
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                        }

                                        {
                                            openKYC ?
                                                <div className="w-[30%] pl-4 ">
                                                    {allowApproveKYC ? <>
                                                    <span className="text-white-primary text-[20px]">Action</span>
                                                    <div className="flex flex-col w-full h-[400px] gap-3  mt-3">
                                                        {/* <button className={!allowApproveKYC ? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => allowApproveKYC ? handleModal("Approve") : null}>Approve KYC</button> */}
                                                        {/* <button className={!allowRejectKYC ? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => allowRejectKYC ? handleModal("Reject") : null}>Reject KYC</button> */}
                                                        {userkycstatusdescription === 'REJECTED' ? '' : <button className="w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" onClick={() => allowApproveKYC ? handleModal("Approve") : null}>Approve KYC</button> }
                                                        {userkycstatusdescription === 'REJECTED' ? 
                                                        <button className={userkycstatusdescription === 'REJECTED'? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => allowRejectKYC ? handleModal("Reject") : null}>Reject KYC</button>
                                                        : <button className={userkycstatusdescription === 'REJECTED'? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => allowRejectKYC ? handleModal("Reject") : null}>Reject KYC</button> }
                                                    </div></> : <>
                                                    <span className="text-white-primary text-[20px]">Action</span>
                                                    <div className="flex flex-col w-full h-[400px] gap-3  mt-3">
                                                        <button className={userkycstatusdescription === 'REJECTED'  ? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => userkycstatusdescription === 'APPROVED' ? handleModal("Reject") : null}>Reject KYC</button>
                                                    </div>
                                                    </> }

                                                </div>
                                                :
                                                <div className="w-[30%] pl-4 ">
                                                    <span className="text-white-primary text-[20px]">Action</span>
                                                    <div className="flex flex-col w-full h-[400px] gap-3 mt-3">
                                                        {userstatusdescription !== 'ACTIVE' ? 
                                                        <button className="w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" onClick={ActiveHandle}>ACTIVE USER</button>
                                                        : '' }
                                                        <button className={userstatusdescription !== 'ACTIVE' ? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold"} onClick={() => userstatusdescription === 'ACTIVE' ? handleModal("Block") : null}>Block User</button>
                                                        {/* <button className={!isClosed ? "w-full bg-black-toGray h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" : "w-full bg-blue-action-button h-[10%] rounded-[8px]  text-white-primary text-[14px] font-semibold"}>Close Account</button> */}
                                                    </div>

                                                </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DetailUser;