import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../Sidebar";
import { useParams } from "react-router-dom";

import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import Modal from "./Modal";
import { randCurrentTimestamp } from "../../../../helpers/RandomGenerator";

const DetailAnoa = () => {
    const navigate = useNavigate();
    const { id, date } = useParams();
    const [detail, setDetail] = useState({});
    const [modal, setModal] = useState(false);
    const [transactionHistory, setTransactionHistory] = useState([]);
    // const [syncModal, setSyncModal] = useState(false);

    const loadTransactionAnoaList = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/anoa?date=' + date +'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });

        if (response.data.status === 1) {

            let result = response.data.data.filter(item => item.invoice === id)[0];
            console.log('res_v01/anoas', result)
            setDetail(result);
            setTransactionHistory(result.historytransaction);
        }
    }, [date, id]);

    useEffect(() => {
        loadTransactionAnoaList();
    }, [loadTransactionAnoaList]);

    const resendHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/resend/' + detail.invoicenumber +'?rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });

        if (response.data.status === 1) {
            setModal(true);
        }
    }
    // const cancelModal = () =>{
    //     setSyncModal(!syncModal)
    // }
    return (
        <>
            {modal ?
                <div className="fixed inset-0 z-[55]" onClick={() => setModal(false)}>
                    <div className="text-white bg-[#3D3F40] w-[511px] h-[430px] rounded-[32px] m-auto mt-[50px]">
                        <img src={process.env.PUBLIC_URL + '/images/components/1.png'} alt="1.png" className="block m-auto" />
                        <h3 className="text-center text-[20px] pt-[30px]">Resend Successfuly</h3>
                    </div>
                </div> : ''}
                {/* {
                    <Modal cancel={cancelModal} modal={syncModal}></Modal>
                } */}
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar />
               
                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                <button onClick={() => navigate('/transaction/anoa')}>
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                                <div className="text-[30px]">Details Transaction</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... /Transaction/ Details Transaction / ...</div>
                                        </div>
                                    </div>
                                    {/* {
                                        detail.pgcmanualsyncstatus === 1 ?
                                            <div className="flex flex-col py-6">
                                                <span className="text-[#FFFF] mb-3 text-xl">Manual Sync</span>
                                                <div className="flex flex-row items-center  w-[100%] rounded-xl py-1.5 px-1.5 ">
                                                    <input type="text" className="h-12 px-3 rounded-lg  w-[50vw] justify-between bg-slate-200"></input>
                                                    <button className="bg-[#4C4DDC] w-[15vw] h-12 text-[#FFFF] font-semibold rounded-xl ml-3 hover:bg-slate-500 py-2" onClick={()=>setSyncModal(!syncModal)}>Submit</button>
                                                </div>

                                            </div>
                                            : null
                                    } */}

                                    <div className="flex flex-row gap-6">
                                        <div className="w-full mt-5">
                                            <div className="bg-[#1E1F23] w-full rounded-[24px] p-[24px] text-white">
                                                <div className="flex flex-row">
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Code</li>
                                                                <li>{detail.transactioncode? detail.transactioncode : 'null'}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Invoice Number</li>
                                                                <li>{detail.invoice}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Date Time</li>
                                                                <li>{detail.date}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Type</li>
                                                                <li>{detail.transactiontype}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Status</li>
                                                                <li>{detail.transactionstatus}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Due Payment</li>
                                                                <li>{detail.duepayment}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Sender Address</li>
                                                                <li>{detail.senderaddress === '' ? '-' : detail.senderaddress}</li>
                                                            </ul>

                                                            <ul>
                                                                <li className="text-[#989B9D]">Recipient Address</li>
                                                                <li>{detail.recipientaddress? detail.recipientaddress : 'null'}</li>
                                                            </ul>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                         
                                                            <ul>
                                                                <li className="text-[#989B9D]">Amount</li>
                                                                <li>{detail.amount} ANOA</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Currency</li>
                                                                <li>{detail.currency}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Payment Method</li>
                                                                <li>{detail.paymentmethod}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Platform</li>
                                                                <li>{detail.platform}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Total</li>
                                                                <li>{detail.total}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Unique Code</li>
                                                                <li>{detail.uniquecode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Fee Transaction</li>
                                                                <li>{detail.fee}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Rate</li>
                                                                <li>{detail.rate}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {detail.statuscode === 'UNPAID' ?
                                                <button className="bg-[#114CD6] w-full mt-[42px] py-[10px] text-white rounded-lg" onClick={resendHandle}>Resend</button>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className="w-full mt-5">
                                            <div className="bg-[#1E1F23] w-full rounded-[24px] p-[24px] text-white">
                                                <div className="flex flex-row">
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                            <ul>
                                                                <li className="text-[#989B9D]">User Fullname</li>
                                                                <li>{detail.userfullname}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Username</li>
                                                                <li>{detail.username}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                            <ul>
                                                                <li className="text-[#989B9D]">Email</li>
                                                                <li>{detail.email}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {detail.statuscode === 'UNPAID' ?
                                                <button className="bg-[#114CD6] w-full mt-[42px] py-[10px] text-white rounded-lg" onClick={resendHandle}>Resend</button>
                                                : ''}
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-6">
                                        <div className="w-full mt-5">
                                            <div className="bg-[#1E1F23] w-full rounded-[24px] p-[24px] text-white">
                                                <h3 className="mb-3">Transaction History</h3>
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="bg-black text-white-primary">
                                                            <th scope="col" className="py-3 pl-3 rounded-l-lg w-[50%]">
                                                                Date
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactionHistory.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" key={key}>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.timestamp}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.transactionstatus}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailAnoa;