import { useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import Signature from "../../../../helpers/Signature";

import CryptoJS from "crypto-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddAdmin = () => {
    const inputFullName = useRef();
    const inputEmail = useRef();
    const inputUsername = useRef();
    const inputRole = useRef();

    const [errorFullName, setErrorFullname] = useState('');
    const [errorUsername, setErrorUsername] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorRole, setErrorRole] = useState('');
    const [textModal, setTextModal] = useState('');

    const [modalSuccess, setModalSuccess] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let body = {
            'admin_fullname' : inputFullName.current.value,
            'admin_name' : inputUsername.current.value,
            'admin_email' : inputEmail.current.value,
            'admin_position' : inputRole.current.value
        }

        try {
            let response = await axios.post(process.env.REACT_APP_APIHOST+'/api/admin/v01/create', body, {
                headers : dataHeaders
            });
            
            if(response.data.status === 1){
                setModalSuccess(true);
                setTextModal('Admin  Successfully Created');
    
                inputFullName.current.value = '';
                inputUsername.current.value = '';
                inputEmail.current.value = '';
                inputRole.current.value = '';
            }
    
            if(response.data.status === 0){
                response.data.messages.forEach((result) => {
                    switch(result.code.substring(0, 9)){
                        case '888999005':
                            setErrorFullname(result.errormassage);
                        break;
                        case '888999006':
                            setErrorUsername(result.errormassage);
                        break;
                        case '888999007':
                            setErrorEmail(result.errormassage);
                        break;
                        case '888999008':
                            setErrorRole(result.errormassage);
                        break;
                        default:
                        break;
                    }
                });
            }   
        } catch (error) {
            if(error.request.status === 403){
                navigate('/403');
            }

            if(error.request.status === 400){
                setModalSuccess(true);
                setTextModal('Username already exist');
            }
        }
    }
    return(
        <>
            {modalSuccess ? 
            <div className="fixed inset-0 z-[55]" onClick={() => setModalSuccess(false)}>
                <div className="text-white bg-[#3D3F40] w-[511px] h-[430px] rounded-[32px] m-auto mt-[50px]">
                    <img src={process.env.PUBLIC_URL+'/images/components/1.png'} alt="1.png" className="block m-auto"/>
                    <h3 className="text-center text-[20px] pt-[30px]">{textModal}</h3>
                </div>
            </div> : '' }
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar/>

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="w-full h-full flex flex-col">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row text-white pl-3 gap-5 justify-between">
                                        <div className="flex-1">
                                            <div className="flex flex-row items-start gap-3">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <div className="text-[30px]">Add Admin</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... / Admin  List/ Add Admin / ...</div>
                                        </div>
                                    </div>

                                    <div className="mt-10 text-white">
                                        <div className="flex flex-row gap-5 mb-10">
                                            <div className="flex-1">
                                                <label className="text-white block mb-3">Fullname</label>
                                                <input ref={inputFullName} type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="Chester"></input>
                                                {errorFullName !== '' ? <span>{errorFullName}</span> : '' }
                                            </div>
                                            <div className="flex-1">
                                                <label className="text-white block mb-3">Username</label>
                                                <input ref={inputUsername} type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="Chester997"></input>
                                                {errorUsername !== '' ? <span>{errorUsername}</span> : '' }
                                            </div>
                                        </div>

                                        <div className="flex flex-row gap-5">
                                            <div className="flex-1">
                                                <label className="text-white block mb-3">Email</label>
                                                <input ref={inputEmail} type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="chester@cst.net"></input>
                                                {errorEmail !== '' ? <span>{errorEmail}</span> : '' }
                                            </div>
                                            <div className="flex-1">
                                                <label className="text-white block mb-3">Role</label>
                                                <input ref={inputRole} type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="Admin"></input>
                                                {errorRole !== '' ? <span>{errorRole}</span> : '' }
                                            </div>
                                        </div>

                                        <div className="mt-10 flex justify-end">
                                            <button onClick={onSubmit} className="px-12 py-4 h-full bg-blue-cst text-[14px] rounded-[6px]">
                                                Add Admin
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddAdmin;