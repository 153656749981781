import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";

import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import Modal from "../anoa/Modal";
import { useRef } from "react";
import { randCurrentTimestamp } from "../../../../helpers/RandomGenerator";

const DetailPgc = () => {
    const navigate = useNavigate();
    const { id, date } = useParams();

    const [syncModal, setSyncModal] = useState(false);
    const [detail, setDetail] = useState({});
    const [modal, setModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [amountPlusFee, setAmountPlusFee] = useState('')
    const inputBCTId = useRef('');
    const inputTotalExplorer = useRef('');
    const [callback, setCallback] = useState([]);

    const loadTransactionPgcList = useCallback(async () => {
        console.log('CALLING API')
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/pgc?date=' + date +'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });

        if (response.data.status === 1) {
            let result = response.data.data.filter(item => item.transactioncode === id)[0];
            console.log('detail', result)
            setDetail(result);
            // let amount = result.tableinvoiceamount
            // let totalFee = result.tableinvoicefeetotal
            // setAmountPlusFee(parseFloat(amount) + parseFloat(totalFee))
        }
    }, [date, id]);

    useEffect(() => {
        loadTransactionPgcList();
    }, [loadTransactionPgcList]);

    const resendHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/resend/' + detail.invoicenumber +'?rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });

        if (response.data.status === 1) {
            setModal(true);
        }
    }
    const cancelModal = () => {
        if (isError === false) {
            loadTransactionPgcList();
            setSyncModal(!syncModal)
        }
        else {
            setSyncModal(!syncModal)
        }


    }
    const submitManualSync = async () => {
        let TransactionId = inputBCTId.current.value
        // let AdjustmentAmount = inputAdjustedAmount.current.value
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        let body =
        {
            "transactionid": TransactionId,
            "invoice": detail.invoicenumber,
            "amount": amountPlusFee
        }
        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        let response = await axios.post(process.env.REACT_APP_APIHOST + '/api/transaction/v01/manual-sync', body, {
            headers: dataHeaders
        });
        try {
            if (response.data.status === 1) {
                setIsError(false);
                setSyncModal(!syncModal);
            }
            if (response.data.status === 0) {
                console.log('WOU', response.data.messages)
                let errMsg;
                let errCode;
                if (typeof (response.data.messages) !== 'undefined') {
                    errMsg = response.data.messages[0].message
                    errCode = response.data.messages[0].code
                }
                else {
                    errMsg = response.data.message[0].message
                    errCode = response.data.message[0].code
                }
                setIsError(true);
                setErrMessage(' ' + errCode + ' ' + ' ' + errMsg);
                setSyncModal(!syncModal);
            }
        } catch (error) {

        }
        console.log('response', response)


        // console.log(TransactionId, AdjustmentAmount)
    }
    const calculateAmount = () => {
        let total = inputTotalExplorer.current.value;
        // let amount = detail.tableinvoiceamount
        let totalFee = detail.tableinvoicefeetotal
        let fixRate = "0.67"
        let calculate = (parseFloat(total) - parseFloat(totalFee)) / parseFloat(fixRate)
        setAmountPlusFee(calculate)
    }

    const historyCallback = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let body = {
            'invoice' : detail.invoicenumber
        }

        let response = await axios.post(process.env.REACT_APP_APIHOST + '/api/transaction/v01/history-callback', body, {
            headers: dataHeaders
        });

        if(response.data.status === 1){
            setCallback(response.data.data);
        }
    }, [detail]);

    useEffect(() => {
        historyCallback();
    }, [historyCallback]);
    return (
        <>
            {modal ?
                <div className="fixed inset-0 z-[55]" onClick={() => setModal(false)}>
                    <div className="text-white bg-[#3D3F40] w-[511px] h-[430px] rounded-[32px] m-auto mt-[50px]">
                        <img src={process.env.PUBLIC_URL + '/images/components/1.png'} alt="1.png" className="block m-auto" />
                        <h3 className="text-center text-[20px] pt-[30px]">Resend Successfuly</h3>
                    </div>
                </div> : ''}

            {
                <Modal cancel={cancelModal} modal={syncModal} isError={isError} errMessage={errMessage} ></Modal>
            }
            <div className="w-full min-h-screen p-4 bg-pure-black">
                {
                    syncModal ? null : <Sidebar />
                }


                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                <button onClick={() => navigate('/transaction/pgc')}>
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                                <div className="text-[30px]"> Details Transaction PGC</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... /Transaction/ Details Transaction / ...</div>
                                        </div>
                                    </div>
                                    {
                                        detail.manualsysncstatus === true ?
                                            <div className="flex flex-col py-6">
                                                {/* <span className="text-[#FFFF] mb-3 text-xl">Manual Sync</span> */}
                                                {/* <div className="flex flex-row gap-3">
                                                    <div className="flex flex-col ">
                                                        <span className="text-[#FFFF] text-sm font-semibold">Recomended Recreate Invoice</span>
                                                        <input ref={inputTotalExplorer} type="text" className=" w-[15vw] justify-between h-12 px-3 mt-1 rounded-lg bg-slate-200"></input>

                                                    </div>
                                                    <div className="flex flex-col mt-6">
                                                        <button onClick={() => calculateAmount()} className={"bg-[#114CD6] w-[10vw] h-12 text-[#FFFF] font-semibold rounded-xl hover:bg-slate-500 py-2"}>Get Adjusted Amount</button>
                                                    </div>

                                                </div>
                                                <div className="text-[#FFFF] mt-6 mb-3">
                                                    Adjusted Amount ( {detail.tableinvoicecurrencycode} ) = (<span> Total explorer</span> - <span>Total Fee </span>) / Fix Rate

                                                </div>
                                                <div className="text-[#FFFF]">
                                                    Adjusted Amount ( {detail.tableinvoicecurrencycode} ) = (<span> Total explorer</span> - <span> {detail.tableinvoicefeetotal}</span>) / 0.67

                                                </div>
                                                <div className="text-[#FFFF] mt-3 mb-6 flex flex-row gap-3">
                                                    Adjusted Amount ( {detail.tableinvoicecurrencycode} ) =
                                                    <input type="text" value={amountPlusFee} disabled className="px-3 py-1 bg-[#FFFF] text-black rounded-lg"></input>
                                                    {detail.tableinvoicecurrencycode}
                                                </div> */}
                                                {/* <div className="flex flex-row flex-wrap gap-3 w-[100%] rounded-xl items-center py-1.5 px-1.5 ">
                                                    <div className="flex flex-col w-[25vw]">
                                                        <span className="text-[#FFFF] text-sm font-semibold">Blockchain Transaction Id</span>
                                                        <input ref={inputBCTId} type="text" className="justify-between h-12 px-3 mt-1 rounded-lg bg-slate-200"></input>
                                                    </div>
                                                    <div className="flex flex-col w-[25vw]">
                                                        <div className="flex flex-row">
                                                            <span className="text-[#FFFF] text-sm font-semibold">Adjusted Amount</span>
                                                            <span className="texst-[#FFFF] text-sm">*amount from user input</span>
                                                        </div>

                                                        <input value={amountPlusFee} type="text" className="justify-between h-12 px-3 mt-1 rounded-lg bg-slate-200"></input>
                                                    </div>
                                                    <div className="flex flex-col mt-6">
                                                        <button onClick={submitManualSync} className={amountPlusFee !== "" ? "bg-red-600 w-[10vw] h-12 text-[#FFFF] font-semibold rounded-xl hover:bg-slate-500 py-2" : "bg-black-toGray w-[10vw] h-12 text-[#FFFF] font-semibold rounded-xl hover:bg-slate-500 py-2"}>Submit</button>
                                                    </div>
                                                </div> */}

                                            </div>
                                            : null
                                    }

                                    <div className="flex flex-row gap-6">
                                        <div className="w-full mt-5">
                                            <div className="bg-[#1E1F23] w-full rounded-[24px] p-[24px] text-white">
                                                <div className="flex flex-row">
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Code</li>
                                                                <li>{detail.transactioncode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">User Id</li>
                                                                <li>{detail.tableinvoiceuserid}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Transaction Timestamp</li>
                                                                <li>{detail.timestamp}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Quotation Index</li>
                                                                <li>{detail.tableinvoicequotationindex}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Expire Timestamp</li>
                                                                <li>{detail.tableinvoiceexpiretimestamp}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Type Transaction</li>
                                                                <li>{detail.type}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Sender</li>
                                                                <li>{detail.sender === '' ? '-' : detail.sender}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Receipent</li>
                                                                <li>{detail.recepient}</li>
                                                            </ul>
                                                            {/* <ul>
                                                                <li className="text-[#989B9D]">User Wallet Address</li>
                                                                <li>{detail.tableinvoicewalletaddresses}</li>
                                                            </ul> */}
                                                            <ul>
                                                                <li className="text-[#989B9D]">Unique Code</li>
                                                                <li>{detail.tableinvoiceuniquecode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Blockchain Transaction Id</li>
                                                                <li>{detail.tableinvoicetransactionid}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="flex-1">
                                                        <div className="flex flex-col gap-5">
                                                            <ul>
                                                                <li className="text-[#989B9D]">Invoice Number</li>
                                                                <li>{detail.invoicenumber}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Total Invoice</li>
                                                                <li>{detail.tableinvoicetotal} {detail.tableinvoicecurrencycode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Amount</li>
                                                                <li>{detail.amount} {detail.tableinvoicecurrencycode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Fee Transaction</li>
                                                                <li>{detail.fee} {detail.tableinvoicecurrencycode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Status Transaction</li>
                                                                <li>
                                                                    <span className="block bg-[#114CD6] py-[6px] w-[108px] mt-2 text-center rounded-[24px]">{detail.status}</span>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Currency Code</li>
                                                                <li>{detail.tableinvoicecurrencycode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Invoice Platform</li>
                                                                <li>{detail.tableinvoiceplatformcode}</li>
                                                            </ul>
                                                            <ul>
                                                                <li className="text-[#989B9D]">Invoice Platform</li>
                                                                <li>{detail.tableinvoiceagentcode}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-6">
                                        <div className="w-full mt-5">
                                            <div className="bg-[#1E1F23] w-full rounded-[24px] p-[24px] text-white">
                                                <h3 className="mb-3 text-[#989B9D] text-[20px]">History Callback </h3>

                                                <div className="overflow-auto">
                                                    {callback.map((result, key) => (
                                                        <div key={key}>
                                                            {JSON.stringify(result)}<br/><br/><hr/><br/><br/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {detail.status === 'UNPAID' ?
                                        <button className="bg-[#114CD6] w-full mt-[42px] py-[10px] text-white rounded-lg" onClick={resendHandle}>Resend</button>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailPgc;