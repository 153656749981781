const Modal = (props) => {
    const { cancel, modal, isError, errMessage } = props;
    return (
        <>
            {
                modal ? <div className="bg-bg1 backdrop-blur-[3px] fixed z-10 inset-0">
                    <div className="fixed inset-0 z-20">
                        <div className="relative w-full h-full ">
                            <div className="absolute translate-y-[-50%] top-[50%] w-full">
                                <div className="max-w-[725px] m-auto bg-pure-black h-[500px] rounded-xl">
                                    <div className="flex flex-col items-center justify-between h-full px-6 py-12">
                                        {
                                            isError ?
                                                <>
                                                    <span className="text-3xl text-[#FFFF] font-semibold">Manual Sync Failed</span>
                                                    <div className="h-[20%]">
                                                        <span className="text-2xl text-[#FFFF] font-semibold">{errMessage}</span>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <span className="text-3xl text-[#FFFF] font-semibold">Manual Sync Success</span>
                                                    <div className="h-[20%]">
                                                        <img src={process.env.PUBLIC_URL + '/images/logo/CSTLogo.svg'} alt="brand" className="w-[100%] h-[100%]"></img>
                                                    </div>
                                                </>

                                        }




                                        <button onClick={cancel} className="bg-[#114CD6] text-[#FFFF] w-[90%] h-[5vh] text-lg font-semibold rounded-xl">Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }

        </>
    )

}
export default Modal;