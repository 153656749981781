import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { positions, Provider as Providers } from "react-alert";

import AlertTemplate from 'react-alert-template-basic'
import Login from '../components/auth/Login';
import UserList from '../components/layout/container/user/UserList';
import DetailUser from '../components/layout/container/user/DetailUser';
import Logout from '../components/auth/Logout'
import UserLogs from '../components/layout/container/user/UserLogs';
import AdminList from '../components/layout/container/admin/AdminList';
import AddAdmin from '../components/layout/container/admin/AddAdmin';
import AdminDetails from '../components/layout/container/admin/AdminDetails';
import AdminLog from '../components/layout/container/admin/AdminLog';
import NewPassword from '../components/auth/NewPassword';
import NoService from '../components/layout/NoServices';
import PgcList from '../components/layout/container/transaction/pgc/PgcList';
import DetailPgc from '../components/layout/container/transaction/pgc/DetailPgc';
import AnoaList from '../components/layout/container/transaction/anoa/AnoaList';
import DetailAnoa from '../components/layout/container/transaction/anoa/DetailAnoa';

const options = {
    timeout: 5000,
    position: positions.TOP_RIGHT
};

const Router = () => {
    return (
        <>
            <Providers template={AlertTemplate} {...options}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path='/userlist' element={<UserList/>} />
                        <Route path='/userlist/detailUser/:id' element={<DetailUser />} />
                        <Route path='/userlog' element={<UserLogs/>}/>
                        <Route path='/admin' element={<AdminList/>}/>
                        <Route path='/admin/add' element={<AddAdmin/>}/>
                        <Route path='/admin/detail/:id' element={<AdminDetails/>}/>
                        <Route path='/admin/log' element={<AdminLog/>}/>
                        <Route path='/new-password' element={<NewPassword/>}/>
                        <Route path='/transaction/pgc' element={<PgcList/>}/>
                        <Route path='/transaction/pgc/:id/:date' element={<DetailPgc/>}/>
                        <Route path='/transaction/anoa' element={<AnoaList/>}/>
                        <Route path='/transaction/anoa/:id/:date' element={<DetailAnoa/>}/>
                        <Route path='/403' element={<NoService/>}/>
                    </Routes>
                </BrowserRouter>
            </Providers>
        </>
    );
}

export default Router;