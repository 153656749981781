import { useRef, useState } from "react";

import Signature from "../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
    const inputPassword = useRef();
    const navigate = useNavigate();

    const [errorPassword, setErrorPassword] = useState('');

    const setPasswordHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let body = {
            'adminpassword' : inputPassword.current.value
        }

        let response = await axios.post(process.env.REACT_APP_APIHOST+'/api/admin/v01/password', body, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            navigate('/')
        }

        if(response.data.status === 0){
            response.data.messages.forEach(result => {
                switch(result.code.substring(0, 9)){
                    case '888999034':
                        setErrorPassword(result.errormassage);
                    break;
                    default:
                    break;
                }
            });
        }
    }
    return(
        <>
            <div className="h-full w-full">
                <div className="bg-black h-full w-full bg-no-repeat bg-cover bg-bottom px-[10%]">
                    <div className="flex h-screen place-items-center">
                        <div className="flex flex-col ml-auto gap-20 items-center rounded-3xl bg-[#1E1F23] bg-opacity-70 py-[5%] px-[5%]">
                            <div>
                                <h1 className="max-sm:text-3xl md:text-4xl  text-white-primary">
                                    Set Password
                                </h1>
                            </div>
                            <div className="flex flex-col gap-52">
                                <div className="flex flex-col gap-16">
                                    <div className="max-sm:w-60 sm:w-88 md:w-96 flex flex-col items-center py-2">
                                        <span className="self-start text-white-primary">Set Password</span>
                                        <div className="w-[100%] flex flex-row bg-white-primary rounded-md">
                                            <div className="flex w-[10%] " >
                                                <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/password.svg'})` }}></div>
                                            </div>
                                            <input ref={inputPassword} type="password" className="appearance-none bg-white-primary border-none w-[90%] text-gray-700 mr-1 py-1 px-2 leading-tight  focus:outline-none" placeholder="******" aria-label="******"/>
                                        </div>
                                        {errorPassword !== '' ? <span className="text-white">{errorPassword}</span> : ''}
                                    </div>

                                    <button onClick={setPasswordHandle} className="h-12 opacity-100 max-sm:w-60 sm:w-88 md:w-96 text-lg text-white-primary transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover">
                                        Create Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewPassword;