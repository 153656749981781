import { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";

import Signature from "../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import { randCurrentTimestamp } from "../../../helpers/RandomGenerator";

const UserLogs = () => {
    const [logList, setLogList] = useState([]);
    const [switchData, setSwitchData] = useState(false);
    const [reachMaxList, setReachMaxList] = useState(false);
    const [page, setPage] = useState(0);
    const [totalList] = useState(10);

    const searchFilter = useRef();

    const loadUserLogs = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/user/v02/logs/account?limit=100' +'&rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setLogList(response.data.data.data);
        }
    }, []); 

    useEffect(() => {
        loadUserLogs();
    }, [loadUserLogs]);

    const switchLogDataAcct = () => {
        setSwitchData(false);
        loadUserLogs();
    }

    const switchLogDataKyc = async () => {
        setSwitchData(true);

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/user/v02/logs/kyc' +'?rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setLogList(response.data.data.data);
        }
    }

    const searchFilterHandle = async () => {
        let input = searchFilter.current.value;
        
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let urlRequest;

        if(switchData){
            urlRequest = process.env.REACT_APP_APIHOST+'/api/user/v02/logs/kyc?search='+input +'&rand='+ randCurrentTimestamp;
        }else{
            urlRequest = process.env.REACT_APP_APIHOST+'/api/user/v02/logs/account?search='+input +'&rand='+ randCurrentTimestamp;
        }

        let response = await axios.get(urlRequest, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setLogList(response.data.data.data);
        }
    }

    const filterButton = async (value) => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let urlRequest;

        if(switchData){
            urlRequest = process.env.REACT_APP_APIHOST+'/api/user/v02/logs/kyc?filterAction='+value +'&rand='+ randCurrentTimestamp;
        }else{
            urlRequest = process.env.REACT_APP_APIHOST+'/api/user/v02/logs/account?filterAction='+value +'&rand='+ randCurrentTimestamp;
        }

        let response = await axios.get(urlRequest, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setLogList(response.data.data.data);
        }
    }

    const loadPaginationData =  async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/user/v02/logs/account?limit='+totalList+'&page='+page +'&rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.data.data.length === 0) {
            setReachMaxList(true)
        }else{
            setLogList((logList) => [...logList, ...response.data.data.data])
        }
    }

    const scrollHandle = (e) => {
        const scrollTop = Math.round(e.target.scrollTop)
        
        if (e.target.scrollHeight - scrollTop === e.target.clientHeight){
            if(!reachMaxList){
                setPage(page + 1);
            }
        }
    }

    useEffect(() => {
        if (page !== 0) {
            if (!reachMaxList) {
                loadPaginationData(page);
            }
        }
    }, [page]);

    return(
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar/>

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                                <div className="text-[30px]">Log</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... /User/Log/Account</div>
                                        </div>
                                        <div className="flex flex-row w-[25%] h-[50px]">
                                            <button onClick={switchLogDataAcct} className={switchData === false ? 'flex w-[50%] h-full bg-blue-cst text-[14px] rounded-l-[6px] justify-center items-center border-[2px] border-white-primary' : 'flex w-[50%] h-full text-[14px] bg-white-primary text-gray rounded-l-[6px] justify-center items-center border-[2px] border-white-primary'}>
                                                Account
                                            </button>
                                            <button onClick={switchLogDataKyc} className={switchData === true ? 'flex w-[50%] h-full bg-blue-cst text-[14px] rounded-r-[6px] justify-center items-center border-[2px] border-white-primary' : 'flex w-[50%] h-full text-[14px] bg-white-primary text-gray rounded-r-[6px] justify-center items-center border-[2px] border-white-primary'}>
                                                Data KYC
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className=" w-[80%] mt-5">
                                            <div className="relative overflow-x-auto overflow-y-auto h-[700px] pr-3" onScroll={(e) => scrollHandle(e)}>
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="text-white-primary bg-black-toGray">
                                                            <th scope="col" className="py-3 pl-3">
                                                                Action Date
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Activity Log
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {logList.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" key={key}>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.timestamp}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.message}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="flex flex-col min-h-full w-[20%] gap-6">
                                            <div className="block">
                                                <span className="text-[14px] text-white-primary mb-3">Search</span>
                                                <div className="flex flex-row  bg-black-toGray rounded-[4px] px-2 py-2 gap-2 mt-3">

                                                    <input type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" onChange={searchFilterHandle} placeholder="Field" ref={searchFilter}></input>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5 16.5L6.5 11.5M4.83333 7.33333C4.83333 10.555 7.44501 13.1667 10.6667 13.1667C13.8883 13.1667 16.5 10.555 16.5 7.33333C16.5 4.11167 13.8883 1.5 10.6667 1.5C7.44501 1.5 4.83333 4.11167 4.83333 7.33333Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                </div>
                                            </div>
                                            {/* <div>
                                                <span className="text-[14px] text-white-primary mb-3">Filter by Date</span>
                                                <div className="flex flex-row gap-5 mt-3">
                                                    <input type="date" className="h-[40px] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2"></input>
                                                    <input type="date" className="h-[40px] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2"></input>
                                                </div>
                                            </div> */}
                                            <div className="flex flex-col h-[100%] gap-10 mt-3">
                                                <div className="flex flex-col h-[45%]">
                                                    <button onClick={() => filterButton('ACTIVATE USER')} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Activated</button>
                                                    <button onClick={() => filterButton('APPROVE KYC')} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Approve</button>
                                                    <button onClick={() => filterButton('REJECTED KYC')} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Rejected</button>
                                                    <button onClick={() => filterButton('FREEZE USER')} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Freeze</button>
                                                    <button onClick={() => filterButton('BLOCK USER')} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">Blocked</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserLogs;