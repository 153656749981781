import { useCallback, useState, useEffect } from "react";
import Sidebar from "../../../Sidebar";
import { useNavigate } from "react-router-dom";

import Signature from "../../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import axios from "axios";
import FormatDate from "../../../../helpers/FormatDate";
import { randCurrentTimestamp } from "../../../../helpers/RandomGenerator";

const AnoaList = () => {
    const [transactionList, setTransactionList] = useState([]);
    const [date, setDate] = useState('');
    const navigate = useNavigate();
    const [currentType, setCurrentType] = useState('');
    const [currentStatus, setCurrentStatus] = useState([]);
    const [tempTransactionList, setTempTransactionList] = useState([])
    const [filterType, setFilterType] = useState([
        {
            label: 'Top Up',
            id: 'TOPUP',
            isSelected: false
        },
        {
            label: 'Transfer',
            id: 'TRANSFER',
            isSelected: false
        }
    ])
    const [filterStatus, setFilterStatus] = useState([
        {
            label: 'Unpaid',
            id: 'UNPAID',
            isSelected: false
        },
        {
            label: 'Paid',
            id: 'PAID',
            isSelected: false
        },
        {
            label: 'Confirmed',
            id: 'CONFIRMED',
            isSelected: false
        },
        {
            label: 'Completed',
            id: 'COMPLETE',
            isSelected: false
        },
        {
            label: 'Canceled',
            id: 'CANCELED',
            isSelected: false
        }
    ])

    useEffect(() => {
        setDate(FormatDate(new Date()));
    }, []);
    const _handleFilterType = (id) => {
        console.log('id', id)

        let temp = filterType;

        temp.forEach(elem => {
            if (elem.id === id) {
                elem.isSelected = !elem.isSelected;
            }
        });

        if (temp[0].isSelected && !temp[1].isSelected) {
            getFilterTypeData('TOPUP', currentStatus)
        }
        if (!temp[0].isSelected && temp[1].isSelected) {
            getFilterTypeData('TRANSFER', currentStatus)
        }
        if ((temp[0].isSelected && temp[1].isSelected) || (!temp[0].isSelected && !temp[1].isSelected)) {
            getFilterTypeData('', currentStatus)
        }
        setFilterType(temp);
    }
    const clearFilter = () => {

        setCurrentType('');
        setCurrentStatus([]);

        filterStatus.forEach(elem => {
            elem.isSelected = false
        });
        filterType.forEach(elem => {
            elem.isSelected = false
        });
    }

    const _handleFilterStatus = (id) => {
        console.log('id', id)
        let temp = filterStatus;
        let temp2 = [];
        temp.forEach(elem => {
            if (elem.id === id) {
                elem.isSelected = !elem.isSelected;
            }
            if (elem.isSelected === true) {
                console.log('ELEM', elem)
                temp2.push(elem.id)
            }
        })
        setFilterStatus(temp)

        getFilterStatusData(currentType, temp2)
    };
    const getFilterStatusData = (type, status) => {
        console.log('STATUS', status)
        console.log('CURRENTSTATUS', currentStatus)
        console.log('TYPE', type)
        console.log('tempTransactionListtempTransactionList', tempTransactionList)
        let temp = (type === '') ? tempTransactionList : transactionList;
        let newArr = [];
        if (status.length === 0) {
            setCurrentStatus(status)
            // setTransactionList(tempTransactionList);
            getFilterTypeData(currentType, status);
        }
        //kalo gk kosong dy loop cari data tampilin
        else {
            //kalo status bertambah atau berubah dy ambil ke TEMP
            if (status === currentStatus && type === '') {
                let temp = tempTransactionList;
                temp.forEach(elem => {
                    if (status.includes(elem.transactionstatus)) {
                        console.log('LOOP1', elem);
                        newArr.push(elem);
                    }
                })
                setCurrentStatus(status)
                setTransactionList(newArr);
            }
            //kalo enggak langsung ambil ke CURRENT TransactionList
            if (status !== currentStatus && type !== '') {
                console.log('status_2', status)
                let temp2 = []
                let temp = tempTransactionList;
                console.log('tempTransactionList_2', temp)
                console.log('type_2', type)
                temp.forEach(elem => {
                    if (elem.transactiontype === type) {
                        if (status.includes(elem.transactionstatus)) {
                            console.log('LOOP1', elem);
                            newArr.push(elem);
                        }
                    }
                });
                setCurrentStatus(status)
                setTransactionList(newArr);
            }
            if (status !== currentStatus && type === '') {
                console.log('1', status)

                let temp = tempTransactionList;
                console.log('2', temp)
                temp.forEach(elem => {
                    if (status.includes(elem.transactionstatus)) {
                        console.log('LOOP1', elem);
                        newArr.push(elem);
                    }
                })
                setCurrentStatus(status)
                setTransactionList(newArr);
            }
        }
    }
    const getFilterTypeData = (type, status) => {
        console.log('TL', transactionList)
        console.log('currentStatus', currentStatus)
        console.log('type', type)
        console.log('status', status)
        let temp = status.length === 0 ? tempTransactionList : transactionList;
        var temp2 = [];
        if (type === 'TOPUP') {
            temp.forEach(elem => {
                if (elem.transactiontype === 'TOPUP') {
                    temp2.push(elem);
                }
            })
            setCurrentType(type);
            setTransactionList(temp2);
            console.log('TEMPTL', temp2)
        }
        if (type === 'TRANSFER') {
            temp.forEach(elem => {
                if (elem.transactiontype === 'TRANSFER') {
                    temp2.push(elem);
                }
            })
            setCurrentType(type);
            setTransactionList(temp2);
            console.log('TEMPTL', temp2)
        }
        if (type === '' && status.length === 0) {
            console.log('is Empty')

            setCurrentType(type);
            setTransactionList(temp);

        }
        //case type is null dan status exist manggil getFilterStatusData
        if (type === '' && status.length !== 0) {
            setCurrentType(type);
            getFilterStatusData(type, currentStatus);
        }
    }


    const dateToday = () => {
        let today = new Date();
        let date = today.getDate();
        let month = (today.getMonth() + 1).toString().padStart(2, '0');
        let year = today.getFullYear();

        let result = year + '-' + month + '-' + date;
        setDate(result);

        return result;
    }

    const loadTransactionAnoaList = useCallback(async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/anoa?date=' + FormatDate(new Date())+'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });
        console.log('resv_01/anoa', response)

        if (response.data.status === 1) {
            setTransactionList(response.data.data);
            setTempTransactionList(response.data.data);
        }
    }, []);

    const handleFilterDate = async (e) => {
        setDate(e.target.value);
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST + '/api/transaction/v01/anoa?date=' + e.target.value+'&rand='+ randCurrentTimestamp, {
            headers: dataHeaders
        });

        if (response.data.status === 1) {
            clearFilter();
            setTempTransactionList(response.data.data);
            setTransactionList(response.data.data);
        }
    }

    useEffect(() => {
        loadTransactionAnoaList();
    }, [loadTransactionAnoaList]);


    console.log('hi i rendering')
    return (
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar />

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                                <div className="text-[30px]">Transaction</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... /Transaction/</div>
                                        </div>
                                        {/* <div className="flex flex-row w-[25%] h-[50px]">
                                            <button className="flex w-[50%] h-full bg-blue-cst text-[14px] rounded-l-[6px] justify-center items-center border-[2px] border-white-primary">
                                                Top Up
                                            </button>
                                            <button className="flex w-[50%] h-full text-[14px] bg-white-primary text-gray rounded-r-[6px] justify-center items-center border-[2px] border-white-primary">
                                                Transfer
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className=" w-[80%] mt-5 overflow-auto">
                                            <div className="relative overflow-x-auto overflow-y-auto h-[700px] pr-3">
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="text-white-primary bg-black-toGray">
                                                            <th scope="col" className="py-3 pl-3">
                                                                Transaction Code
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Type
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Date
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Invoice Number
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Username
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                Email
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3 text-center">
                                                                Status
                                                            </th>
                                                            {/* <th scope="col" className="py-3 pl-3">
                                                                Amount
                                                            </th> */}
                                                            <th scope="col" className="py-3 pl-3">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactionList.length > 0 ? 
                                                        transactionList.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" onClick={() => navigate('/transaction/anoa/' + result.invoice + '/' + date)} key={key}>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.transactioncode}
                                                                </td>

                                                                {/* <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.statuscode}
                                                                </td> */}
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.transactiontype}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {new Date(result.date).toLocaleString("en-US", {
                                                                        year: "numeric",
                                                                        month: "2-digit",
                                                                        day: "2-digit",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit",
                                                                        timeZone: "UTC"
                                                                    })}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.invoice !== null ? result.invoice : '-'}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.username}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.email}
                                                                </td>

                                                                {
                                                                    result.transactionstatus === 'COMPLETE' ?
                                                                        <td className="py-6 pl-3 font-semibold capitalize">
                                                                            <div className="bg-[#367A59] py-2 px-3 flex justify-center rounded-3xl">
                                                                                Completed
                                                                            </div>
                                                                        </td>
                                                                        :
                                                                        result.transactionstatus === 'CANCELED' ?
                                                                            <td className="py-6 pl-3 font-semibold capitalize">
                                                                                <div className="bg-[#1E1F23] py-2 px-3 flex justify-center rounded-3xl">
                                                                                    Canceled
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            result.transactionstatus === 'CONFIRM' ?
                                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                                    <div className="bg-[#114CD6] py-2 px-3 flex justify-center rounded-3xl">
                                                                                        Confirm
                                                                                    </div>
                                                                                </td>
                                                                                :
                                                                                result.transactionstatus === 'PAID' ?
                                                                                    <td className="py-6 pl-3 font-semibold capitalize">
                                                                                        <div className="bg-[#BF6919] py-2 px-3 flex justify-center rounded-3xl">
                                                                                            Paid
                                                                                        </div>
                                                                                    </td>
                                                                                    :
                                                                                    result.transactionstatus === 'UNPAID' ?
                                                                                        <td className="py-6 pl-3 font-semibold capitalize">
                                                                                            <div className="bg-[#BD251C] py-2 px-3 flex justify-center rounded-3xl">
                                                                                                Unpaid
                                                                                            </div>
                                                                                        </td>
                                                                                        : null
                                                                }
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    <button onClick={() => navigate('/transaction/anoa/' + result.transactioncode + '/' + date)}>
                                                                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect x="0.166656" width="40" height="40" rx="8" fill="#0028FC" />
                                                                            <path d="M17.6666 20C17.6666 21.3807 18.7859 22.5 20.1666 22.5C21.5474 22.5 22.6666 21.3807 22.6666 20C22.6666 18.6193 21.5474 17.5 20.1666 17.5C18.7859 17.5 17.6666 18.6193 17.6666 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M28.1181 20C27.0562 16.6191 23.8976 14.1667 20.1663 14.1667C16.4349 14.1667 13.2763 16.6191 12.2144 20.0001C13.2763 23.381 16.4349 25.8334 20.1663 25.8334C23.8976 25.8334 27.0562 23.3809 28.1181 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer">
                                                            <td className="py-6 pl-3 font-semibold text-center capitalize" colSpan={15}>
                                                                Data is empty
                                                            </td>
                                                        </tr> }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="flex flex-col min-h-full w-[20%] gap-6">
                                            {/* <div className="block">
                                                <span className="mb-3 text-lg text-white-primary">Search</span>
                                                <div className="flex flex-row  bg-black-toGray rounded-[4px] px-2 py-2 gap-2 mt-3">

                                                    <input type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" placeholder="Field"></input>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5 16.5L6.5 11.5M4.83333 7.33333C4.83333 10.555 7.44501 13.1667 10.6667 13.1667C13.8883 13.1667 16.5 10.555 16.5 7.33333C16.5 4.11167 13.8883 1.5 10.6667 1.5C7.44501 1.5 4.83333 4.11167 4.83333 7.33333Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div> */}
                                            <div>
                                                <span className="mb-3 text-lg text-white-primary">Filter by Date</span>
                                                <div className="flex flex-row gap-5 mt-3">
                                                    <input type="date" onChange={(e) => handleFilterDate(e)} value={date} className="h-[40px] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2"></input>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-10 mt-3" >
                                                <div className="flex flex-col " >
                                                    <span className="text-lg text-white-primary">Filter By Type</span>
                                                    {
                                                        filterType.map((key, index) => {
                                                            return key.isSelected ?
                                                                <button className="h-[5vh] w-full bg-[#4C4DDC] rounded-[4px] text-white-primary text-center text-[14px] mt-3" onClick={() => _handleFilterType(key.id)}>{key.label}</button>
                                                                :
                                                                <button className="h-[5vh] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3" onClick={() => _handleFilterType(key.id)}>{key.label}</button>
                                                        })
                                                    }
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-lg text-white-primary">Filter By Status</span>
                                                    {
                                                        filterStatus.map((key, index) => {
                                                            return key.isSelected ?
                                                                <button className="h-[5vh] w-full bg-[#4C4DDC] rounded-[4px] text-white-primary text-center text-[14px] mt-3" onClick={() => _handleFilterStatus(key.id)}>{key.label}</button>
                                                                :
                                                                <button className="h-[5vh] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3" onClick={() => _handleFilterStatus(key.id)}>{key.label}</button>

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnoaList;