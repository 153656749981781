import { useState } from 'react';
import { Link } from 'react-router-dom';


const Sidebar = () => {
    const [userDropdown, setUserDropdown] = useState(false);
    const [adminDropdown, setAdminDropdown] = useState(false);
    const [transaction, setTransaction] = useState(false);
    const [ercDropdown, setErcDropdown] = useState(false);
    const [trcDropdown, setTrcDropdown] = useState(false);
    const [bepDropdown, setBepDropdown] = useState(false);
    // //#region 
    // const isDashboardActivePage = useSelector(state => state.isDashboardActivePage);
    // const isTuhistoryActivePage = useSelector(state => state.isTuhistoryActivePage);
    // const isTfhistoryActivePage = useSelector(state => state.isTfhistoryActivePage);
    // const isTrHistoryActivePage = useSelector(state => state.isTrHistoryActivePage);

    // var backGroundDash = '';
    // var backGroundTuHist = '';
    // var backGroundTfHist = '';
    // var backGroundTrHist = '';

    // if (isDashboardActivePage) {
    //     backGroundDash = 'bg-blue-cst-primary-active';
    // }

    // if (isTuhistoryActivePage) {
    //     backGroundTuHist = 'bg-blue-cst-primary-active';
    // }

    // if (isTfhistoryActivePage) {
    //     backGroundTfHist = 'bg-blue-cst-primary-active';
    // }

    // if (isTrHistoryActivePage) {
    //     backGroundTrHist = 'bg-blue-cst-primary-active';
    // }

    //#endregion
    //    var backGroundDash = '';
    // var backGroundTuHist = '';
    // var backGroundTfHist = '';
    // var backGroundTrHist = '';

    // var backGroundDash = 'bg-blue-cst-primary-active';
    var backGroundTuHist = 'bg-blue-cst-primary-active';

    return (
        <aside className="w-16 md:w-64 fixed z-50 h-fit mt-5 ml-2 bg-black-toGray rounded-[32px]" aria-label="Sidebar">
            <div className="flex flex-col h-[100%]  pt-7 md:pt-0 px-4 py-2 rounded-[32px] justify-between pb-10 ">
                <div className='flex flex-col h-[72.5vh] overflow-y-auto'>
                    <Link to={'/userlist'} className="flex items-center mb-10 md:block">
                        <img src={process.env.PUBLIC_URL + '/images/logo/CSTLogo.svg'} alt="brand" className="mt-4 ml-4 logo-desktop max-sm:w-24 sm:w-30 md:w-36"></img>
                    </Link>

                    <ul className="space-y-2">
                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>
                            <span onClick={() => setUserDropdown(!userDropdown)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1666 16.9865H18.3333V15.3199C18.3333 13.9392 17.214 12.8199 15.8333 12.8199C15.037 12.8199 14.3276 13.1922 13.8698 13.7723M14.1666 16.9865H5.83329M14.1666 16.9865V15.3199C14.1666 14.773 14.0613 14.2507 13.8698 13.7723M5.83329 16.9865H1.66663V15.3199C1.66663 13.9392 2.78591 12.8199 4.16663 12.8199C4.96297 12.8199 5.67234 13.1922 6.13016 13.7723M5.83329 16.9865V15.3199C5.83329 14.773 5.93865 14.2507 6.13016 13.7723M6.13016 13.7723C6.74454 12.2374 8.24564 11.1532 9.99996 11.1532C11.7543 11.1532 13.2554 12.2374 13.8698 13.7723M12.5 6.1532C12.5 7.53391 11.3807 8.6532 9.99996 8.6532C8.61925 8.6532 7.49996 7.53391 7.49996 6.1532C7.49996 4.77249 8.61925 3.6532 9.99996 3.6532C11.3807 3.6532 12.5 4.77249 12.5 6.1532ZM17.5 8.6532C17.5 9.57367 16.7538 10.3199 15.8333 10.3199C14.9128 10.3199 14.1666 9.57367 14.1666 8.6532C14.1666 7.73272 14.9128 6.98653 15.8333 6.98653C16.7538 6.98653 17.5 7.73272 17.5 8.6532ZM5.83329 8.6532C5.83329 9.57367 5.0871 10.3199 4.16663 10.3199C3.24615 10.3199 2.49996 9.57367 2.49996 8.6532C2.49996 7.73272 3.24615 6.98653 4.16663 6.98653C5.0871 6.98653 5.83329 7.73272 5.83329 8.6532Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="flex-1 ml-3 text-white whitespace-nowrap">User</span>
                                <div className={userDropdown  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                            </span>

                            <ul className={userDropdown ? 'block' : 'hidden'}>
                                <li>
                                    <a href="/userlist" className="text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        UserList
                                    </a>
                                </li>
                                <li>
                                    <a href="/userlog" className="text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        Log
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>
                            <span onClick={() => setAdminDropdown(!adminDropdown)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1666 16.9865H18.3333V15.3199C18.3333 13.9392 17.214 12.8199 15.8333 12.8199C15.037 12.8199 14.3276 13.1922 13.8698 13.7723M14.1666 16.9865H5.83329M14.1666 16.9865V15.3199C14.1666 14.773 14.0613 14.2507 13.8698 13.7723M5.83329 16.9865H1.66663V15.3199C1.66663 13.9392 2.78591 12.8199 4.16663 12.8199C4.96297 12.8199 5.67234 13.1922 6.13016 13.7723M5.83329 16.9865V15.3199C5.83329 14.773 5.93865 14.2507 6.13016 13.7723M6.13016 13.7723C6.74454 12.2374 8.24564 11.1532 9.99996 11.1532C11.7543 11.1532 13.2554 12.2374 13.8698 13.7723M12.5 6.1532C12.5 7.53391 11.3807 8.6532 9.99996 8.6532C8.61925 8.6532 7.49996 7.53391 7.49996 6.1532C7.49996 4.77249 8.61925 3.6532 9.99996 3.6532C11.3807 3.6532 12.5 4.77249 12.5 6.1532ZM17.5 8.6532C17.5 9.57367 16.7538 10.3199 15.8333 10.3199C14.9128 10.3199 14.1666 9.57367 14.1666 8.6532C14.1666 7.73272 14.9128 6.98653 15.8333 6.98653C16.7538 6.98653 17.5 7.73272 17.5 8.6532ZM5.83329 8.6532C5.83329 9.57367 5.0871 10.3199 4.16663 10.3199C3.24615 10.3199 2.49996 9.57367 2.49996 8.6532C2.49996 7.73272 3.24615 6.98653 4.16663 6.98653C5.0871 6.98653 5.83329 7.73272 5.83329 8.6532Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="flex-1 ml-3 text-white whitespace-">Admin</span>
                                <div className={adminDropdown  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </span>

                            <ul className={adminDropdown ? 'block' : 'hidden'}>
                                <li>
                                    <a href="/admin" className=" text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        AdminList
                                    </a>
                                </li>
                                <li>
                                    <a href="/admin/log" className=" text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        Log
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>
                            <span onClick={() => setTransaction(!transaction)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1667 7.49984V5.83317C14.1667 4.9127 13.4205 4.1665 12.5 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V10.8332C2.5 11.7536 3.24619 12.4998 4.16667 12.4998H5.83333M7.5 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V9.1665C17.5 8.24603 16.7538 7.49984 15.8333 7.49984H7.5C6.57953 7.49984 5.83333 8.24603 5.83333 9.1665V14.1665C5.83333 15.087 6.57953 15.8332 7.5 15.8332ZM13.3333 11.6665C13.3333 12.587 12.5871 13.3332 11.6667 13.3332C10.7462 13.3332 10 12.587 10 11.6665C10 10.746 10.7462 9.99984 11.6667 9.99984C12.5871 9.99984 13.3333 10.746 13.3333 11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                                <span className="flex-1 ml-3 text-white whitespace-">Transaction</span>
                                <div className={transaction  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </span>

                            <ul className={transaction ? 'block' : 'hidden'}>
                                <li>
                                    <a href="/transaction/pgc" className=" text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        PGC
                                    </a>
                                    <a href="/transaction/anoa" className=" text-white flex items-center py-3 px-6 pl-[58px] text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        ANOA
                                    </a>
                                </li>
                            </ul>
                        </li>
                        {/* <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>
                            <span onClick={() => setErcDropdown(!ercDropdown)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1667 7.49984V5.83317C14.1667 4.9127 13.4205 4.1665 12.5 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V10.8332C2.5 11.7536 3.24619 12.4998 4.16667 12.4998H5.83333M7.5 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V9.1665C17.5 8.24603 16.7538 7.49984 15.8333 7.49984H7.5C6.57953 7.49984 5.83333 8.24603 5.83333 9.1665V14.1665C5.83333 15.087 6.57953 15.8332 7.5 15.8332ZM13.3333 11.6665C13.3333 12.587 12.5871 13.3332 11.6667 13.3332C10.7462 13.3332 10 12.587 10 11.6665C10 10.746 10.7462 9.99984 11.6667 9.99984C12.5871 9.99984 13.3333 10.746 13.3333 11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                                <span className="flex-1 ml-3 text-white whitespace-">ERC20</span>
                                <div className={ercDropdown  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </span>

                            <ul className={ercDropdown ? 'block' : 'hidden'}>
                                <li>
                                    <span onClick={() => window.open('https://etherscan.io/advanced-filtered?tadd=0xeCEC1A707f2312758b0d0357F858812d77EbEFFe&txntype=2&tkn=0xdac17f958d2ee523a2206206994597c13d831ec7')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">ERC20 - Top Up</span>
                                    </span>
                                    <span onClick={() => window.open('https://etherscan.io/advanced-filtered?fadd=0xc80A3e90D01cE1e6A24ac778760f453617804B49&txntype=2&tkn=0xdac17f958d2ee523a2206206994597c13d831ec7')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">ERC20 - Withdraw</span>
                                    </span>
                                </li>
                            </ul>
                        </li> */}
                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>

                            <span onClick={() => setTrcDropdown(!trcDropdown)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1667 7.49984V5.83317C14.1667 4.9127 13.4205 4.1665 12.5 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V10.8332C2.5 11.7536 3.24619 12.4998 4.16667 12.4998H5.83333M7.5 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V9.1665C17.5 8.24603 16.7538 7.49984 15.8333 7.49984H7.5C6.57953 7.49984 5.83333 8.24603 5.83333 9.1665V14.1665C5.83333 15.087 6.57953 15.8332 7.5 15.8332ZM13.3333 11.6665C13.3333 12.587 12.5871 13.3332 11.6667 13.3332C10.7462 13.3332 10 12.587 10 11.6665C10 10.746 10.7462 9.99984 11.6667 9.99984C12.5871 9.99984 13.3333 10.746 13.3333 11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                                <span className="flex-1 ml-3 text-white whitespace-">TRC20</span>
                                <div className={trcDropdown  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </span>

                            <ul className={trcDropdown ? 'block' : 'hidden'}>
                                <li>
                                    <span onClick={() => window.open('https://tronscan.org/#/address/TPKeuuCnPWe71nBWLqovAjEt9mg8EC23sC/transfers')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">TRC20 - Top Up</span>
                                    </span>
                                    <span onClick={() => window.open('https://tronscan.org/#/address/TXBA7Fi4ocBbQP7k6HU1x7oQbUXVJZA5yW/transfers')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">TRC20 - Withdraw</span>
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>

                            <span onClick={() => setBepDropdown(!bepDropdown)} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1667 7.49984V5.83317C14.1667 4.9127 13.4205 4.1665 12.5 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V10.8332C2.5 11.7536 3.24619 12.4998 4.16667 12.4998H5.83333M7.5 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V9.1665C17.5 8.24603 16.7538 7.49984 15.8333 7.49984H7.5C6.57953 7.49984 5.83333 8.24603 5.83333 9.1665V14.1665C5.83333 15.087 6.57953 15.8332 7.5 15.8332ZM13.3333 11.6665C13.3333 12.587 12.5871 13.3332 11.6667 13.3332C10.7462 13.3332 10 12.587 10 11.6665C10 10.746 10.7462 9.99984 11.6667 9.99984C12.5871 9.99984 13.3333 10.746 13.3333 11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                                <span className="flex-1 ml-3 text-white whitespace-">BEP20</span>
                                <div className={bepDropdown  ?'rotate-180 transform' : ''}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9L12 16L19 9" stroke="#707375" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </span>

                            <ul className={bepDropdown ? 'block' : 'hidden'}>
                                <li>
                                    <span onClick={() => window.open('https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955?a=0xC8C70adD5E228D1E2E1e52D9bB46e6C32353C9AF')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">BEP20 - Top Up</span>
                                    </span>
                                    <span onClick={() => window.open('https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955?a=0x029AC8D7DdD8d23c555C0271f8B29DB905f745be')} className="flex items-center cursor-pointer py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                        <span className="flex-1 ml-3 text-white whitespace-">BEP20 - Withdraw</span>
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='flex space-y-2  mt-[5vh] md:block' >
                    <a href="/new-password" className="flex items-center py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1667 13.3333L17.5 9.99999M17.5 9.99999L14.1667 6.66666M17.5 9.99999L5.83333 9.99999M10.8333 13.3333V14.1667C10.8333 15.5474 9.71405 16.6667 8.33333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83333C2.5 4.45262 3.61929 3.33333 5 3.33333H8.33333C9.71405 3.33333 10.8333 4.45262 10.8333 5.83333V6.66666" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>


                        <span className="flex-1 ml-3 text-white whitespace-nowrap">Set Password</span>
                    </a>
                    <a href="/logout" className="flex items-center py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1667 13.3333L17.5 9.99999M17.5 9.99999L14.1667 6.66666M17.5 9.99999L5.83333 9.99999M10.8333 13.3333V14.1667C10.8333 15.5474 9.71405 16.6667 8.33333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83333C2.5 4.45262 3.61929 3.33333 5 3.33333H8.33333C9.71405 3.33333 10.8333 4.45262 10.8333 5.83333V6.66666" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>


                        <span className="flex-1 ml-3 text-white whitespace-nowrap">Log Out</span>
                    </a>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;