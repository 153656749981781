import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../Sidebar";

import CryptoJS from "crypto-js";
import axios from "axios";
import Signature from "../../../../helpers/Signature";
import { randCurrentTimestamp } from "../../../helpers/RandomGenerator";

const AdminDetails = () => {
    const { id } = useParams();
    const [PermissionHandle, setPermissionHandle] = useState(false);
    const [detailAdmin, setDetailAdmin] = useState({});
    const [permissionData, setPermissionData] = useState([]);
    const [changeUpdatePermission, setChangeUpdatePermission] = useState([]);

    const [modal,setModal] = useState(false);

    const navigate = useNavigate();

    const loadDetailAdmin = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        
        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        
        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/admin/v01/'+id+'?rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setDetailAdmin(response.data.data.detail);
        }
    }, [id]);

    const loadPermissions = useCallback( async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        
        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/admin/v01/permissions/'+id +'?rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setPermissionData(response.data.data)
        }
    }, []);

    useEffect(() => {
        loadDetailAdmin();
        loadPermissions();
    }, [loadDetailAdmin, loadPermissions]);

    const onAddingItem = (e, i, key, id) => {
        let checkbox;
        if(e.target.checked === false){
            checkbox = 0;
        }else{
            checkbox = 1;
        }

        const dataValues = [...permissionData];
        dataValues[key].permisions[i].feature_status = checkbox;
        setPermissionData(dataValues);

        let dataPermission = dataValues[key].permisions.filter(item => item.feature_index === id);

        setChangeUpdatePermission([...changeUpdatePermission, dataPermission[0]]);
    }

    const saveChangePermission = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let body = {
            'feature' : changeUpdatePermission
        }

        try {
            let response = await axios.put(process.env.REACT_APP_APIHOST+'/api/admin/v01/permission/'+id, body, {
                headers : dataHeaders
            });
    
            if(response.data.status === 1){
                loadDetailAdmin();
                setPermissionHandle(false);
            }
        } catch (error) {
            if(error.response.status === 403){
                navigate('/403');
            }
        }
    }

    const deleteAdminHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.delete(process.env.REACT_APP_APIHOST+'/api/admin/v01/'+id, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            navigate('/admin');
        }
    }

    const resetPasswordHandle = async () => {
        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));

        let payloadSignature = [];
        let signature = Signature(payloadSignature);

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        let response = await axios.get(process.env.REACT_APP_APIHOST+'/api/admin/v01/reset/'+id +'?rand='+ randCurrentTimestamp, {
            headers : dataHeaders
        });

        if(response.data.status === 1){
            setModal(true);
        }
    }

    return(
        <>
            {PermissionHandle ? 
            <div className="fixed inset-0 z-[55]">
                <div className="bg-[#1E1F23] w-[804px] inset-0 m-auto mt-14 rounded-[16px] p-[32px]">
                    <div className="flex flex-row items-center">
                        <div className="flex-1">
                            <p className="text-[#FFFFFF] text-[20px]">Edit Permission</p>
                        </div>
                        <div>
                            <button onClick={() => { setPermissionHandle(false); loadPermissions();}} className="cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="mt-[32px] text-white">
                        <div className="flex flex-row">
                            <div className="w-[90%]">
                                <div className="flex flex-row flex-wrap justify-between">
                                    {permissionData.map((result, key) => (
                                        <div className="flex-[0 0 215px] mb-5" key={key}>
                                            <label className="text-[#989B9D] block mb-[10px] pl-10">{result.category}</label>
                                            <div className="flex flex-row">
                                                <div className="flex-1">
                                                    <div className="flex flex-col gap-5">
                                                        {result.permisions.map((results, keys) => (
                                                            <div className="flex flex-row gap-5" key={keys}>
                                                                <input type="checkbox" className="w-[24px]" onChange={(e) => onAddingItem(e, keys, key, results.feature_index)} checked={results.feature_status === 1 ? true : false }/>
                                                                <label>{results.feature_description}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="w-[50%]">
                                <div className="flex flex-col items-end gap-5">
                                    <button onClick={saveChangePermission} className="bg-[#4C4DDC] py-[12px] px-[20px] rounded-[6px] font-semibold w-[166px]">Save Changes</button>
                                    {/* <button className="bg-[#E0E0F3] py-[12px] px-[20px] rounded-[6px] font-semibold text-[#4C4DDC] w-[166px]">Discard</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : '' }
            {modal ? 
            <div className="fixed inset-0 z-[55]" onClick={() => setModal(false)}>
                <div className="text-white bg-[#3D3F40] w-[511px] h-[430px] rounded-[32px] m-auto mt-[50px]">
                    <img src={process.env.PUBLIC_URL+'/images/components/1.png'} alt="1.png" className="block m-auto"/>
                    <h3 className="text-center text-[20px] pt-[30px]">Reset Password Successfuly</h3>
                </div>
            </div> : '' }
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar/>

                <div className="w-full min-h-screen pl-[295px]">
                    <div className="flex flex-col w-full h-full">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="flex flex-row justify-between pl-3 text-white">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-3">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3333 25.3334L4 16M4 16L13.3333 6.66669M4 16L28 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <div className="text-[30px]">Details Admin</div>
                                            </div>

                                            <div className="text-[#BABEC1]">... / Admin  List/ Admin Details / ...</div>
                                        </div>
                                    </div>

                                    <div className="flex min-h-screen mt-5">
                                        <div className="w-[70%] pr-3">
                                            <span className="text-white-primary text-[20px]">Data Admin</span>
                                            <div className="relative mt-3 overflow-x-hidden">
                                                <table className="w-full text-sm text-left">
                                                    <tbody>
                                                        <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                            <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                Full Name
                                                            </td>
                                                            <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                {detailAdmin.admin_fullname}
                                                            </td>
                                                        </tr>

                                                        <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                            <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                Username
                                                            </td>
                                                            <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                {detailAdmin.admin_name}
                                                            </td>
                                                        </tr>

                                                        <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                            <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                Email
                                                            </td>
                                                            <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                {detailAdmin.admin_email}
                                                            </td>
                                                        </tr>

                                                        <tr className="py-12 border-b-8 cursor-pointer border-pure-black bg-black-toGray text-white-primary hover:bg-slate-400" >
                                                            <td className="py-3 pl-3 border-r-8 border-pure-black font-semibold capitalize bg-black-toGray w-[25%]">
                                                                Position
                                                            </td>
                                                            <td className="py-3 pl-3 font-semibold capitalize bg-black-toGray">
                                                                {detailAdmin.admin_position}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="w-[30%] pl-4 ">
                                            <span className="text-white-primary text-[20px]">Action</span>
                                            <div className="flex flex-col w-full h-[400px] gap-3  mt-3">
                                                <button className="w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" onClick={() => setPermissionHandle(true)}>Edit Permissions</button>
                                                <button className="w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" onClick={deleteAdminHandle}>Delete Admin</button>
                                                <button className="w-full bg-blue-action-button h-[10%] rounded-[8px] text-white-primary text-[14px] font-semibold" onClick={resetPasswordHandle}>Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDetails;