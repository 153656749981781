import Sidebar from "./Sidebar"

const NoService = () => {
    return(
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">
                <Sidebar/>

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="w-full h-full flex flex-col">
                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <h3 className="text-center text-white font-bold text-[30px]">Service is not Valid!</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoService;